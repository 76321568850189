import { SanityImageSource } from '@sanity/asset-utils'
import sanityImage from '@sanity/image-url'
import { options } from './client'

const builder = sanityImage(options)
export function urlFor(source) {
  return builder.image(source)
}

export function blurImage(source: SanityImageSource): string {
  const image = builder.image(source).width(64).quality(30).blur(50).fit('clip').toString()
  if (image === null && process.env.NODE_ENV === 'development') {
    console.warn(`Could not create image from `, source)
  }
  return image || ''
}
