import { Text } from '@components/ui/typography'
import { theme } from '@config/theme'
import * as styles from './styles'
import React, { FC } from 'react'
import { motion } from 'framer-motion'

export type NotificationTypes = 'error' | 'success' | 'idle'

type NotificationProps = {
  status: NotificationTypes
}

export const Notification: FC<NotificationProps> = ({ status }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: '50%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{
        opacity: 0,
      }}
      transition={{ ease: theme.transitions.motion.notification, duration: 0.4 }}
      className={styles.parent}
    >
      <div className={styles.notification} data-variant={status}>
        <Text as="span" variant="micro">
          {status === 'error'
            ? 'Something went wrong, please try again'
            : 'Thanks for your request, we will be in touch soon'}
        </Text>
      </div>
    </motion.div>
  )
}
