import Box from '@components/ui/layout/Box';
import { theme } from '@config/theme';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { motion } from 'framer-motion';
import React, { FC, useEffect } from 'react';
import { Close } from './Close';
import { FormSection } from './form-section';
import { Image } from './Image';
import * as styles from './styles';
import { RemoveScroll } from 'react-remove-scroll';
import { useGetInTouchContext } from './Context';
const contentParent = "c1kgf7ob";
type GetInTouchProps = {};
export const GetInTouch: FC<GetInTouchProps> = ({}) => {
  const {
    setIsVisible
  } = useGetInTouchContext();
  useEffect(() => {
    function handleEsc(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        setIsVisible(false);
      }
    }
    window.addEventListener('keyup', handleEsc);
    return () => window.removeEventListener('keyup', handleEsc);
  }, []); // eslint-disable-line

  return <RemoveScroll>
      <DialogOverlay onDismiss={() => setIsVisible(false)} className={styles.overlay}>
        <DialogContent aria-label="Get in touch form" className={contentParent}>
          <motion.div initial={{
          opacity: 0,
          y: 10
        }} animate={{
          opacity: 1,
          y: 0
        }} transition={{
          ease: theme.transitions.motion.default,
          duration: 0.5
        }} style={{
          width: '100%',
          height: '100%'
        }}>
            <div className={styles.contentParent}>
              <Box className={styles.content}>
                <FormSection />
                <Image alt="" />
                <Close handleClick={() => setIsVisible(false)} />
              </Box>
            </div>
          </motion.div>
        </DialogContent>
      </DialogOverlay>
    </RemoveScroll>;
};

require("./index.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./index.tsx");