import { Theme } from '@config/theme'
import BlockContent from '@sanity/block-content-to-react'
import React, { FC } from 'react'
import { SanityBlock, SanityKeyed } from 'sanity-codegen'
import { ThemeSystemProps } from 'theme-system'
import { Heading, Text } from '../ui/typography'
import * as styles from './styles'

type Variants = 'special' | 'regular' | 'large' | 'getInTouch' | 'small'

type RichTextBlockProps = Pick<ThemeSystemProps<Theme>, 'color'> & {
  content?: Array<SanityKeyed<SanityBlock>>
  variant?: Variants
}

export const RichTextBlock: FC<RichTextBlockProps> = ({ content, variant = 'regular' }) => {
  return (
    <section className={styles.content} data-variant={variant}>
      <BlockContent blocks={content} serializers={getSerializers(variant)} />
    </section>
  )
}

const getSerializers = (variant: Variants) => {
  const textVariant = ['getInTouch', 'small'].includes(variant)
    ? 'bodySmall'
    : variant === 'regular'
    ? 'bodyRegular'
    : 'bodyLarge'

  return {
    list: function List(props) {
      return <ul className={styles.list}>{props.children}</ul>
    },
    listItem: function ListItem(props) {
      return (
        <Text as="li" variant={textVariant}>
          {props.children}
        </Text>
      )
    },
    types: {
      block(props) {
        switch (props.node.style) {
          case 'h1':
            return (
              <Heading variant="h2" as="h1" className={styles.heading}>
                {props.children}
              </Heading>
            )
          case 'h2':
            return (
              <Heading variant="h3" as="h2" mb="8" className={styles.heading}>
                {props.children}
              </Heading>
            )
          case 'h3':
            return (
              <Heading variant="h4" as="h3" mb={{ _: '4', sm: '6' }} className={styles.heading}>
                {props.children}
              </Heading>
            )
          case 'h4':
            return (
              <Heading variant="h5" as="h4" mb="4" className={styles.heading}>
                {props.children}
              </Heading>
            )
          case 'h5':
            return (
              <Heading variant="h5" as="h5" className={styles.heading}>
                {props.children}
              </Heading>
            )
          default:
            return (
              <Text variant={textVariant} as="p" mb={{ _: '6', sm: '8' }}>
                {props.children}
              </Text>
            )
        }
      },
    },
  }
}
