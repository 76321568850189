import { cx } from '@linaria/core';
import React, { FC, LabelHTMLAttributes } from 'react';
import { FieldValidationVariants, LabelPosition } from './Field';
const styles = "s1eobv2r";
type Props = LabelHTMLAttributes<HTMLLabelElement> & {
  status?: 'error' | 'idle';
  validation?: FieldValidationVariants;
  position: LabelPosition;
};
const Label: FC<Props> = ({
  status = 'idle',
  validation = 'mandatory',
  position,
  children,
  className,
  ...rest
}) => {
  return <label className={cx(styles, className)} data-status={status} data-position={position} {...rest}>
      {children}
      {validation === 'optional' && <span> (optional)</span>}
    </label>;
};
export default Label;

require("./Label.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Label.tsx");