import React, { HTMLAttributes, FC } from 'react';
import { cx } from '@linaria/core';
const styles = "so2lrsm";
type Props = HTMLAttributes<HTMLAnchorElement> & {
  as: React.ElementType;
};
export const StyledLink: FC<Props> = React.forwardRef(({
  children,
  className,
  as,
  ...rest
}, ref) => {
  const Element = as;
  return (
    // @ts-ignore
    <Element className={cx(className, styles)} {...{
      ...rest,
      ref
    }}>
        {children}
      </Element>
  );
});
StyledLink.displayName = 'StyledLink';

require("./Link.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Link.tsx");