import Box from '@components/ui/layout/Box'
import { Heading, Text } from '@components/ui/typography'
import globalContent from '@config/globalContent'
import Link from 'next/link'
import React, { useState } from 'react'
import { Modal } from '../cookies/Modal'
import { CookieOptions, useCookies } from '../cookies/store'
import * as styles from './styles'

const {
  footerAddressCompanyName,
  footerAddressCountry,
  footerAddressHeading,
  footerAddressPostalCity,
  footerAddressStreet,
  footerContactEmail,
  footerContactText,
  footerTitle,
} = globalContent.fields.footer

export function Footer() {
  const [showCookieSettings, setShowCookieSettings] = useState(false)
  const cookieStore = useCookies()

  const setCookieSettings = (settings: CookieOptions[]) => {
    cookieStore.setPreferences(settings)
  }

  return (
    <>
      <Box as="footer" className={styles.container} id="contact">
        <Box display="grid" className={styles.grid}>
          <div className={styles.contact}>
            <Heading as="h3" variant="h3" color="white" mb={{ _: '4', md: '6' }}>
              {footerTitle}
            </Heading>
            <Text as="p" variant="bodyLarge" color="white" mb={{ _: '6', md: '8' }}>
              {footerContactText}
            </Text>
            <Text
              as="div"
              variant="bodyLarge"
              className={styles.contactLink}
              mb={{ _: '2', md: '4' }}
            >
              <a href={`mailto:${footerContactEmail}`}>{footerContactEmail}</a>
            </Text>
          </div>
          <div className={styles.address}>
            <Text as="p" variant="bodyRegular" color="white" fontWeight="bold" mb={{ _: '2' }}>
              {footerAddressHeading}
            </Text>
            <address>
              <Text variant="bodyRegular" color="shade400">
                {footerAddressCompanyName}
              </Text>
              <Text variant="bodyRegular" color="shade400">
                {footerAddressStreet}
              </Text>
              <Text variant="bodyRegular" color="shade400">
                {footerAddressPostalCity}
              </Text>
              <Text variant="bodyRegular" color="shade400">
                {footerAddressCountry}
              </Text>
            </address>
          </div>
          <nav className={styles.links}>
            <Text as="span" className={styles.link} fontWeight="semiBold" variant="micro">
              <Link href="/blog">Blog</Link>
            </Text>
            <Text as="span" className={styles.link} fontWeight="semiBold" variant="micro">
              <Link href="/changelog">Changelog</Link>
            </Text>
            <Text as="span" className={styles.link} fontWeight="semiBold" variant="micro">
              <button className={styles.cookieLink} onClick={() => setShowCookieSettings(true)}>
                Cookies
              </button>
            </Text>
            <Text as="span" className={styles.link} fontWeight="semiBold" variant="micro">
              <a href="/privacy-policy.pdf" rel="noopener noreferrer" target="_blank">
                Privacy statement
              </a>
            </Text>
          </nav>
        </Box>
      </Box>
      {showCookieSettings && (
        <Modal
          onRequestClose={() => setShowCookieSettings(false)}
          handleSetCookies={(settings) => setCookieSettings(settings)}
        />
      )}
    </>
  )
}
