import { StyledLink } from '@components/ui/Link'
import { Text } from '@components/ui/typography'
import { useRouter } from 'next/router'
import React, { FC } from 'react'

type LinksProps = {
  handleClose: () => void
}

const nav = [
  {
    title: 'How it works',
    url: 'how-it-works',
  },
  {
    title: 'Solutions',
    url: 'solutions',
  },
  {
    title: 'About us',
    url: 'about-us',
  },
  {
    title: 'Blog',
    url: 'blog',
  },
  {
    title: 'Join us',
    url: 'join-us',
  },
]

export const Links: FC<LinksProps> = ({ handleClose }) => {
  const router = useRouter()

  return (
    <>
      {nav.map((item) => (
        <li key={item.url} data-link="">
          <StyledLink
            as="a"
            tabIndex={0}
            onClick={(e) => handleNav(e, item.url)}
            title={`go to ${item.title} page`}
          >
            <Text
              as="span"
              variant="buttonLabelSmall"
              color={isActiveUrl(item.url) ? 'purpleWeb' : 'shade400'}
            >
              {item.title}
            </Text>
          </StyledLink>
        </li>
      ))}
    </>
  )

  function handleNav(e, href: string) {
    e.preventDefault()
    router.push(`/${href}`)
    handleClose()
  }

  function isActiveUrl(href: string) {
    return router.pathname?.startsWith(`/${href}`)
  }
}
