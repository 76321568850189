import { ViesusLogo } from '@components/ui/icon'
import { Heading, Text } from '@components/ui/typography'
import globalContent from '@config/globalContent'
import React, { FC } from 'react'
import { Fields } from './Fields'
import * as styles from './styles'

export const FormSection: FC = () => {
  return (
    <div className={styles.wrapper}>
      <ViesusLogo />
      <div className={styles.formwrap}>
        <div className={styles.formParent}>
          <Heading variant="h4" as="h2" mb={{ _: '4', sm: '8' }}>
            {globalContent.fields.getInTouch.getInTouchTitle}
          </Heading>
          <Fields />
        </div>
        <Text variant="micro" fontWeight="medium" color="shade400" className={styles.text}>
          By clicking send you also agree to our{' '}
          <a href="/privacy-policy.pdf" rel="noopener noreferrer" target="_blank">
            privacy statement
          </a>
          .
        </Text>
      </div>
    </div>
  )
}
