export const notification = "nwir215";
export const parent = "pvnhjtb";
export const wrapper = "w13rmlwt";
export const formParent = "f7vczsj";
export const text = "t877qn9";
export const formwrap = "fmqzsb0";
export const submit = "s1854f78";
export const fieldText = "f1mwtzu6";
export const button = "b1xdkah9";

require("./styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");