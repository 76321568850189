import { urlFor } from '@lib/sanity/url-for'
import Head from 'next/head'
import React, { FC } from 'react'
import { HowItWorksSingleton } from '__generated__/sanity'

type SeoProps = {
  data?: HowItWorksSingleton['fields']['seo']
  title?: string
  description?: string
  image?: string
}

export const Seo: FC<SeoProps> = ({ title, description, image, data }) => {
  const usedImage = data?.image ? urlFor(data.image).width(1200).url() : image || ''
  const usedTitle = data?.title ? data.title : title || ''
  const usedDescription = data?.description ? data.description : description || ''
  const titleSuffix = data?.removeTitleSuffix ? '' : ' - Viesus'

  return (
    <Head>
      <title>{`${usedTitle}${titleSuffix}`}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      <meta name="description" content={`${usedDescription}`} key="description" />
      <meta property="og:title" content={`${usedTitle}`} key="og:title" />
      <meta property="og:type" content="website" key="og:type" />
      <meta property="og:image" content={`${usedImage}`} key="og:image" />
      <meta property="og:description" content={`${usedDescription}`} key="og:description" />
      <link rel="shortcut icon" href="/viesus_favicon.png" />
      <link rel="icon" sizes="192x192" href="/viesus_favicon.png" />
      <link rel="apple-touch-icon" href="/viesus_favicon.png" />
      <script
        id="fsc-api"
        src="https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.3/fastspring-builder.min.js"
        type="text/javascript"
        data-storefront="viesus.onfastspring.com/popup-viesus"
        async
      />
    </Head>
  )
}
