import { GetInTouch } from '@components/get-in-touch';
import { Button } from '@components/ui/Button';
import { Hamburger, ViesusLogo } from '@components/ui/icon';
import Box from '@components/ui/layout/Box';
import { Text } from '@components/ui/typography';
import { theme } from '@config/theme';
import { motion } from 'framer-motion';
import Link from 'next/link';
import React, { useState } from 'react';
import { Links } from './Links';
import { Overlay } from './Overlay';
import * as styles from './styles';
import { RemoveScroll } from 'react-remove-scroll';
import { useGetInTouchContext } from '@components/get-in-touch/Context';
export function NavBar() {
  const [expandMenu, setExpandMenu] = useState(false);
  const getInTouch = useGetInTouchContext();
  return <nav className={styles.navBar}>
      <Box alignItems="center" as="div" className={styles.content} display="flex" justifyContent="space-between">
        <Link href="/" className={styles.logo}>
          <ViesusLogo />
        </Link>
        <Box alignItems="center" as="ul" display="flex" justifyContent="space-between" style={{
        zIndex: theme.zIndices.navBar
      }} className={nav}>
          <Links handleClose={() => setExpandMenu(false)} />
          <li>
            <Button variant="gradient" as="button" size="navbar" onClick={() => getInTouch.setIsVisible(true)}>
              <Text as="span" variant="buttonLabelSmall">
                Get in touch
              </Text>
            </Button>
          </li>
          <li>
            <button className={styles.hamburger} data-expanded={expandMenu ? 'true' : 'false'} onClick={() => setExpandMenu(x => !x)}>
              <Hamburger />
            </button>
          </li>
        </Box>
      </Box>
      {expandMenu && <RemoveScroll>
          <Overlay handleClick={() => setExpandMenu(x => !x)} />
          <motion.ul className={styles.expandedMenu} initial={{
        opacity: 0
      }} animate={{
        opacity: 1
      }} transition={{
        ease: theme.transitions.motion.default,
        duration: 0.15
      }}>
            <Links handleClose={() => setExpandMenu(false)} />
          </motion.ul>
        </RemoveScroll>}

      {getInTouch.isVisible && <GetInTouch />}
    </nav>;
}
const nav = "ntma3zd";

require("./index.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./index.tsx");