import { Button } from '@components/ui/Button'
import Field from '@components/ui/form/Field'
import { Text } from '@components/ui/typography'
import { handleFetchResponse } from '@lib/handle-fetch-response'
import { Form, Formik } from 'formik'
import { AnimatePresence } from 'framer-motion'
import React, { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Notification, NotificationTypes } from './Notification'
import * as styles from './styles'

const phone = RegExp(
  /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/g // eslint-disable-line
)

export const schema = Yup.object().shape({
  name: Yup.string().required('This is a required field'),
  company: Yup.string(),
  email: Yup.string()
    .required('This is a required field')
    .email('Please enter a valid email address'),
  phone: Yup.string().matches(phone, 'Please enter a valid phone number'),
  role: Yup.string(),
  message: Yup.string().required('This is a required field'),
})

let statusTimeout

export const Fields: FC = () => {
  const [status, setStatus] = useState<'idle' | 'success' | 'error' | 'loading'>('idle')

  useEffect(() => {
    if (status !== 'idle') {
      statusTimeout = setTimeout(() => {
        setStatus('idle')
      }, 3000)
    }

    return () => {
      if (statusTimeout) {
        clearTimeout(statusTimeout)
      }
    }
  }, [status])

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={{
          name: '',
          company: '',
          email: '',
          phone: '',
          role: '',
          message: '',
        }}
        validateOnBlur
        validateOnChange
        onSubmit={async (values, { resetForm }) => {
          setStatus('loading')
          fetch('/api/send-email', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(values), // body data type must match "Content-Type" header
          })
            .then(handleFetchResponse)
            .then(() => {
              resetForm()
              setStatus('success')
            })
            .catch(() => setStatus('error'))
        }}
      >
        {({ isValid }) => (
          <Form>
            <Field name="name" label="Full name" field="input" />
            <Field name="company" label="Company" field="input" validation="optional" />
            <Field name="email" label="Email address" field="input" />
            <Field name="phone" label="Phone number" field="input" validation="optional" />
            <Field name="role" label="Role" field="input" validation="optional" />
            <Field name="message" label="I would like to use Viesus for..." field="input" />
            <div className={styles.submit}>
              <Text variant="micro" color="shade500" className={styles.fieldText}>
                After clicking ‘send’ we’ll contact you as soon as possible.
              </Text>
              <Button
                variant="gradient"
                size="large"
                type="submit"
                status={status === 'loading' ? 'loading' : 'idle'}
                disabled={!isValid}
                className={styles.button}
                mb={{ _: '4', sm: '0' }}
              >
                Send
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <AnimatePresence>
        {['success', 'error'].includes(status) && (
          <Notification status={status as NotificationTypes} />
        )}
      </AnimatePresence>
    </>
  )
}
