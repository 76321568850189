import React, { FC } from 'react'
import { closeStyles } from './styles'

type CloseProps = {
  handleClick: () => void
}

export const Close: FC<CloseProps> = ({ handleClick }) => {
  return (
    <button className={closeStyles} onClick={handleClick}>
      <span />
      <span />
    </button>
  )
}
