import React, { FC, InputHTMLAttributes, useState, useEffect } from 'react';
import { cx } from '@linaria/core';
type InputStatusProp = {
  status?: 'error';
};
export const styles = "s1luhrsl";
export type InputProps = InputStatusProp & InputHTMLAttributes<HTMLInputElement>;
type Props = InputProps & {
  onFocusChange: (hasFocus: boolean) => void;
};
export const Input: FC<Props> = ({
  onChange,
  status,
  value,
  className,
  onFocusChange,
  onFocus,
  onBlur,
  ...rest
}) => {
  const [isFilled, setIsFilled] = useState(false);
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onFocusChange(false);
    onBlur(event);
  };
  useEffect(() => {
    if (typeof value === 'string') {
      setIsFilled(value.length > 0);
    }
  }, [value]);
  return <input className={cx(styles, className)} onChange={onChange} value={value} data-status={status} data-content={isFilled ? 'filled' : 'empty'} onFocus={() => onFocusChange(true)} onBlur={event => handleBlur(event)} {...rest} />;
};
export default Input;

require("./Input.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Input.tsx");