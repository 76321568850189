import { createClient } from '@sanity/client'
import { ChangelogItem } from '__generated__/sanity'

export const options = {
  projectId: 'a1ay4e5s',
  apiVersion: new Date().toISOString().slice(0, 10),
  dataset: 'production',
  fetch: fetch,
}

const client = createClient({
  ...options,
  useCdn: true,
})

// TODO: setup this preview client properly
const previewClient = createClient({
  ...options,
  useCdn: false,
  withCredentials: true,
  token: process.env.NEXT_PUBLIC_SANITY_TOKEN,
})

export function getSanityClient(preview = false) {
  if (preview || process.env.NODE_ENV === 'development') {
    return previewClient
  }
  return client
}

export const CHANGELOG_ITEMS_PAGE_LIMIT = 8

export async function getChangelogItems(
  page = 0,
  preview = false
): Promise<{ total: number; items: ChangelogItem[] }> {
  const sanity = getSanityClient(preview)
  const start = page * CHANGELOG_ITEMS_PAGE_LIMIT
  const end = start + CHANGELOG_ITEMS_PAGE_LIMIT
  const items = await sanity.fetch(
    `* [_type=="changelog-item"] | order(date desc) [${start}...${end}]`
  )
  const total = await sanity.fetch(`count(*[_type=="changelog-item"])`)

  return { items, total }
}

export const getDrafts = (docs: any[], preview = false) => {
  if (process.env.NODE_ENV === 'development' || preview) {
    const drafts = docs.filter((doc) => doc._id.startsWith('drafts.'))
    return drafts.length ? drafts : docs
  }
  return docs
}
