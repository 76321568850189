import { useField } from 'formik'
import React, { FC, useState } from 'react'
import FieldControl from './FieldControl'
import { Input, InputProps } from './Input'

export type LabelPosition = 'center' | 'top'

type InputType = InputProps & {
  field: 'input'
}

export type FieldValidationVariants = 'mandatory' | 'optional'

export type FieldProps = InputType & {
  label: React.ReactNode
  errorMsg?: string
  validation?: FieldValidationVariants
}

export const Field: FC<FieldProps> = ({
  field = 'input',
  label,
  validation = 'mandatory',
  ...rest
}) => {
  // The ts-ignores and any are a bit messy, but the most important thing
  // is that the Field component is correctly typed based on the value of the field prop and that works.
  // If you use Field and set field as "input", e.g. <Field field="input" />,
  // field will be typed with the Input interface 🤘
  const [fieldProps, meta] = useField<any>(rest.name)
  const [isFocussed, setIsFocussed] = useState(false)

  let status = 'idle'
  let labelPosition: LabelPosition = 'center'
  if (meta.touched && fieldProps.value && !meta.error) {
    status = 'valid'
  }
  if (meta.error && meta.touched) {
    status = 'error'
  }
  if ((typeof meta.value === 'string' && meta.value.length > 0) || isFocussed) {
    labelPosition = 'top'
  }

  return (
    <FieldControl
      validation={validation}
      label={label}
      errorMsg={meta.touched && meta.error ? meta.error : undefined}
      labelPosition={labelPosition}
      field={field}
    >
      <Input
        // @ts-ignore
        status={status}
        onFocusChange={(hasFocus: boolean) => setIsFocussed(hasFocus)}
        {...fieldProps}
        {...rest}
      />
    </FieldControl>
  )
}

export default Field
