import { NavBar } from '@components/page/nav-bar'
import React, { PropsWithChildren } from 'react'
import { ExitPreview } from './exit-preview'
import { Footer } from './footer'

export const Page = ({ children }: PropsWithChildren) => {
  return (
    <>
      <ExitPreview />
      <NavBar />
      {children}
      <Footer />
    </>
  )
}
