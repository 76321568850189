import { parseAll, Theme } from '@config/theme'
import { cx } from '@linaria/core'
import React, { PropsWithChildren } from 'react'
import { SpaceProps } from 'theme-system'
import Box from '../layout/Box'
import { Text } from '../typography'
import Label from './Label'
import { FieldValidationVariants, LabelPosition } from './Field'

export type FieldControlProps = SpaceProps<Theme['space']> & {
  label?: React.ReactNode
  field: string
  errorMsg?: string
  validation?: FieldValidationVariants
  labelPosition: LabelPosition
}

export const FieldControl = ({
  label,
  errorMsg,
  children,
  field,
  labelPosition,
  validation = 'mandatory',
  mb = '4',
  ...rest
}: PropsWithChildren<FieldControlProps>) => {
  return (
    <Box {...rest} mb={mb}>
      <Box position="relative" className={cx(parseAll(rest))}>
        {children}
        {label && (
          <Label
            status={errorMsg ? 'error' : 'idle'}
            validation={validation}
            position={labelPosition}
          >
            {label}
          </Label>
        )}
      </Box>
      {errorMsg ? (
        <Text as="div" variant="micro" mt="2" mb="0" ml="4" fontWeight="bold" color="danger">
          {errorMsg}
        </Text>
      ) : null}
    </Box>
  )
}

export default FieldControl
