import { Text } from '@components/ui/typography'
import { Portal } from '@reach/portal'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import * as styles from './styles'

export const ExitPreview: FC = () => {
  const { isPreview } = useRouter()
  return (
    <>
      {isPreview && (
        <Portal>
          <a href="/api/exit-preview" className={styles.buttonContainer}>
            <Text variant="micro">Exit preview</Text>
          </a>
        </Portal>
      )}
    </>
  )
}
