import { parseAll, Theme } from '@config/theme';
import { cx } from '@linaria/core';
import React, { FC, HTMLAttributes } from 'react';
import { filterProps, ThemeSystemProps } from 'theme-system';
const styles = "s19b1uf3";
type Props = Omit<HTMLAttributes<HTMLElement>, 'color'> & ThemeSystemProps<Theme> & {
  as?: string;
};
export const SmallContainer: FC<Props> = ({
  children,
  className,
  as = 'section',
  ...rest
}) => {
  const Comp: any = as;
  return <Comp className={cx(parseAll(rest), className, styles)} {...filterProps(rest)}>
      {children}
    </Comp>;
};
export default SmallContainer;

require("./SmallContainer.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./SmallContainer.tsx");