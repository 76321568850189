import React from 'react';
import { motion } from 'framer-motion';
const container = "c1hlllt0";
export function Overlay({
  handleClick
}: {
  handleClick: () => void;
}) {
  return <motion.div initial={{
    opacity: 0
  }} animate={{
    opacity: 0.4
  }} transition={{
    ease: [0.65, 0, 0.35, 1],
    duration: 0.1,
    delay: 0.05
  }} className={container} onClick={handleClick} />;
}

require("./Overlay.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Overlay.tsx");