import React, { FC } from 'react'
import globalContent from '@config/globalContent'
import { Text } from '@components/ui/typography'
import { RichTextBlock } from '@components/rich-text-block'
import { SanityBlock, SanityKeyed } from 'sanity-codegen'
import * as styles from './styles'

export const Image: FC<{ alt?: string }> = () => {
  return (
    <div className={styles.imageParent}>
      <img src={globalContent.getInTouchBackground} alt="get in touch" className={styles.image} />

      <Text
        variant="bodySmall"
        color="white"
        mb={{ _: '6', xl: '10' }}
        className={styles.imageTextStyles}
      >
        <RichTextBlock
          content={
            globalContent.fields.getInTouch.getInTouchDescriptionOnImage as Array<
              SanityKeyed<SanityBlock>
            >
          }
          variant="getInTouch"
        />
      </Text>
      <div className={styles.logos}>
        {globalContent.logos.map((logo, index) => (
          <img src={logo.logo.url} alt="logo" key={index} />
        ))}
      </div>
    </div>
  )
}
